import * as React from "react";
import "./textCarousel.scss";
// markup
const TextCarousel = (props) => {
  var ReactRotatingText = require("react-rotating-text");
  return (
    <div className="text-carousel">
      <p>Brauchen Sie </p>
        <ReactRotatingText
          color="#fff"
          items={[
            "eine Webseite?",
            "einen Webshop?",
            "ein Redesign?",
            "ein Logo?",
            "SEO-Hilfe?",
          ]}
        />
    </div>
  );
};

export default TextCarousel;
