import * as React from "react";
import Layout from "../components/layout/layout";
import { Container, Row, Col } from "react-bootstrap";
import "../general.scss";
import websiteSvg from "../images/website_construction.svg";
import TextCarousel from "../components/textCarousel/textCarousel";
import websitePng from "../images/website.png";
import seoPng from "../images/seo.jpg";
import shopPng from "../images/shop.jpg";
import redesignPng from "../images/redesign.png";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Netaro Webdesign</title>
        <meta name="description" content="Webseiten, Webshop und Design | Wählen Sie Netaro für Ihren digitalen Erfolg" />
        <link rel="canonical" href="http://netaro.ch/" />
        <script src="//code.tidio.co/5orxkwmsvzdfzlfoprnfhz7sfwo92jew.js" async></script>
      </Helmet>
      <Layout>
      <script src="//code.tidio.co/5orxkwmsvzdfzlfoprnfhz7sfwo92jew.js" async></script>
        <div
          style={{
            backgroundColor: "#25acbe",
            backgroundImage: "linear-gradient(58deg, #25acbe 0%, #6dd4e2 100%)",
          }}
        >
          <Container fluid="md">
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col md={6}>
                <div>
                  <TextCarousel />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <a className="primary-button" href="tel:0041786713848">
                    JETZT ANRUFEN
                  </a>
                </div>
              </Col>
              <Col
                style={{
                  paddingTop: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
                md={6}
              >
                <img
                  style={{ marginTop: "20px" }}
                  alt="Webseite erstellen"
                  width="100%"
                  src={websiteSvg}
                />
              </Col>
            </Row>
          </Container>
          <div style={{ marginTop: "-30px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
              <path
                fill="#292929"
                fill-opacity="1"
                d="M0,96L34.3,106.7C68.6,117,137,139,206,154.7C274.3,171,343,181,411,181.3C480,181,549,171,617,154.7C685.7,139,754,117,823,106.7C891.4,96,960,96,1029,96C1097.1,96,1166,96,1234,106.7C1302.9,117,1371,139,1406,149.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
              ></path>
            </svg>
          </div>
        </div>

        <Container className="mx-auto" id="leistung">
          <Row className="listing add-margin">
            <Col md={6}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{
                  scale: 0.9,
                  borderRadius: "100%",
                }}
              >
                <img src={websitePng} alt="Webseite erstellen" />
              </motion.div>
            </Col>
            <Col md={6}>
              <h1>Webseiten</h1>
              <p>
                Erreichen Sie mehr Kunden mit Ihrer eigenen Homepage. Eine
                eigene Webseite ist der perfekte Werbeplatz für Ihr Unternehmen.
              </p>
            </Col>
          </Row>
          <Row className="listing add-margin">
            <Col md={6}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{
                  scale: 0.9,
                  borderRadius: "100%",
                }}
              >
                <img src={shopPng} alt="eigener Onlineshop" />
              </motion.div>
            </Col>
            <Col md={6}>
              <h1>Onlineshop</h1>
              <p>
                Ein Shop der jederzeit und überall erreichbar ist. Nützen Sie
                die Gelegenheit Ihren eigenen Onlineshop aufzustellen und Ihren
                Umsatz zu steigern.
              </p>
            </Col>
          </Row>
          <Row className="listing add-margin">
            <Col md={6}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{
                  scale: 0.9,
                  borderRadius: "100%",
                }}
              >
                <img src={seoPng} alt="SEO Dienstleistungen" />
              </motion.div>
            </Col>
            <Col md={6}>
              <h1>SEO</h1>
              <p>
                SEO steht kurz für Suchmaschinen Optimierung. Optimieren Sie
                Ihre Webseite und generieren Sie somit mehr Leads für Ihr
                Unternehmen.
              </p>
            </Col>
          </Row>
          <Row className="listing add-margin">
            <Col md={6}>
              <div>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{
                    scale: 0.9,
                    borderRadius: "100%",
                  }}
                >
                  <img src={redesignPng} alt="Redesign" />
                </motion.div>
              </div>
            </Col>
            <Col md={6}>
              <h1>Redesign</h1>
              <p>
                Neues Logo, neue Farben, neuer Look. Mit einem Redesign
                erfrischen Sie den Look Ihrer Webseite und zeigen Ihrer
                Kundschaft, dass Sie sich aktuell halten.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="contact-section add-margin">
          <Container>
            <Row>
              <Col md={4}>
                <p style={{ margin: "0px", fontSize: "30px" }}>
                  Interessiert?
                  <br /> Kontaktieren Sie Netaro
                </p>
              </Col>
              <Col md={4}>
                <p>
                  Xavier Webdesign
                  <br />
                  Glärnischstrasse 20
                  <br />
                  8820 Wädenswil
                </p>
              </Col>
              <Col md={4}>
                <div className="kontakt-link">
                  <a
                    className="primary-button-arrow"
                    href="mailto:hello@netaro.ch"
                  >
                    Hier geht es weiter <span id="arrow">&#8594;</span>
                  </a>{" "}
                  <br />
                  <br />{" "}
                  <a
                    style={{ marginTop: "900px" }}
                    className="primary-button-arrow"
                    href="tel:0041786713848"
                  >
                    Jetzt anrufen <span id="arrow">&#8594;</span>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
